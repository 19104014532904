import isFunction from 'lodash/isFunction';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { toast } from '../lib/apollo';

const useLoginWithToken = (onSuccess, onError) => {
  const { query, isReady } = useRouter();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        if (isReady && query?.token) {
          const authRes = await signIn('credentials', {
            accessToken: query.token,
            redirect: false
          });

          if (authRes?.error) {
            const { message } = JSON.parse(authRes?.error);
            toast({ message, type: 'error' });
            if (isFunction(onError)) onError();
          } else if (isFunction(onSuccess)) {
            onSuccess(authRes);
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, query]);

  return loading;
};

export default useLoginWithToken;
