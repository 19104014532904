import { useEffect, useState } from 'react';
import { staticDataApi } from './utils';

const useStaticData = (key) => {
  const [data, setData] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const { data: responseData } = await staticDataApi?.get(`/${key}`);
        if (responseData) {
          setData(responseData);
        }
      } catch (err) {
        //
      }
    })();
  }, [key]);

  return data;
};

export default useStaticData;
