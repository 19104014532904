import { TRACK_VALUE } from '../common/constants';

export const pageView = (url) => {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('config', process.env.NEXT_GOOGLE_ANALYTICS_ID, {
      page_path: url
    });
  }
};

export const gaEvent = ({
  category,
  action,
  name = {},
  value = TRACK_VALUE.SUCCESS
}) => {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', action, {
      event_category: category,
      event_label: name,
      value
    });
  }
};

export const logSubscriptionEvent = ({
  category,
  action,
  plan,
  amount,
  duration,
  value = TRACK_VALUE.SUCCESS
}) => {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', action, {
      event_category: category,
      event_label: plan,
      value,
      plan,
      amount,
      duration
    });
  }
};
